import * as React from 'react'
import Layout from '../components/layout'
import '../styles/global.css'
import Slider from '../components/Slider'
import {Helmet} from "react-helmet";

 

export default function Team() {
  return (
    <Layout pageTitle="Team">
      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Team - USI</title>
        <meta name="description" content="We have an energetic and passionate team who take a holistic and challenge-led approach to tackling issues in the places we live, work and play."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        <link rel="apple-touch-icon" sizes="180x180" href="favicon/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="favicon/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="favicon/favicon-16x16.png"/>
        <link rel="manifest" href="favicon/site.webmanifest"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>

        <div class="bg-blue-400 md:p-8 top">
            <div class="container w-full md:w-1/2 p-5 sm:pl-12 pb-16 pt-16 lg:pt-24">
                <h2 class="text-7xl text-white font-heading sm:leading-10">
                    Our Team&nbsp; 
                    <br/>
                </h2>
                <p class="text-2xl text-white pt-9">We have an energetic and passionate team who take
                    a holistic and challenge-led approach to tackling issues in the places we live, work and
                    play.</p>
            </div>
        </div>
        <Slider/>
    </Layout>
  )
}
