import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "transparent", color: "transparent", marginRight: "25px", height: "100%", width: "30%", opacity: "0" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "transparent", marginLeft: "25px", zIndex: "100", height: "100%", width: "30%", opacity: "0" }}
        onClick={onClick}
      />
    );
  }



export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return (
      <div id=""
      className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-16 sm:ml-16 sm:mr-16 sm:mt-16 m-5 transitions-enabled fluid">
        <article className="">
        <Slider {...settings} className="w-full team-1 bg-blue-900 team-box">
                                <img src="/img/team/ralf1.webp"
                                    className="img-responsive team" alt=""/>

                            <div className="">
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                    Ralf’s design interests lie in architecture and placemaking that capitalises on
                                    local community assets, co-design, and potential to create public spaces that
                                    promote people’s health and well-being.
                                </p>
                              </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Dream
                                    Project?<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                    "One where I just do
                                    all the talking..."
                                </p>
                            </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Get in touch<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                <a href="mailto:ralf@urbanscaleinterventions.com">ralf@urbanscaleinterventions.com</a>
                                </p>

                                <div className="flex justify-center pt-2">
                                    <a href="https://www.linkedin.com/in/ralf-alw/" target="_blank" rel="noreferrer"> <img className="p-2"
                                            src="/img/team/linkedin.svg" alt=""/></a>
                                </div>
                            </div>
        </Slider>
        <p className="mt-5 mb-6 team-name"><span className="font-bold text-2xl">Ralf Alwani</span><br/>Partner</p>
        </article>


        <article>
        <Slider {...settings} className="w-full bg-blue-900">
                                <img src="/img/team/jack1.webp"
                                    className="img-responsive team" alt=""/>

                            <div>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                    Jak is an experienced people-centred researcher and designer. He has a PhD in design
                                    for behaviour change and has led international design projects for the likes of
                                    Google, IKEA & Sainsbury’s amongst others.
                                </p>
                              </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Dream
                                    Project?<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                    "Rooftop
                                    Festival stretching
                                    across 50 different venues
                                    throughout the city of Belfast
                                    amongst others."
                                </p>
                            </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Get in touch<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                <a href="mailto:jak@urbanscaleinterventions.com">jak@urbanscaleinterventions.com</a>
                                </p>

                                <div className="flex justify-center pt-2">
                                <a href="https://es.linkedin.com/in/jak-spencer-phd-10918141" target="_blank" rel="noreferrer"> <img className="p-2"
                                            src="/img/team/linkedin.svg" alt=""/></a>
                                </div>
                            </div>
        </Slider>
        <p className="mt-5 mb-6 team-name"><span className="font-bold text-2xl">Jak Spencer</span><br/>Partner</p>
        </article>     

       

        <article>
        <Slider {...settings} className="w-full bg-blue-900">
                                <img src="/img/team/dean1.webp"
                                    className="img-responsive team" alt=""/>

                            <div>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                Dean is an Architectural Researcher and Historian. He studied at
                                    the Bartlett School of Architecture and has worked as a Research Associate at the Royal
                                    College of Art. Dean has worked on a variety of publications across themes of heritage, ecology, urban planning and
                                    policy, wellbeing, and post-humanism.
                                </p>
                              </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Dream
                                    Project?<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                "One that looks beyond the human and truly links the ecological with the architectural."
                                </p>
                            </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Get in touch<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                <a href="mailto:dean@urbanscaleinterventions.com">dean@urbanscaleinterventions.com</a>
                                </p>

                                <div className="flex justify-center pt-2">
                                    <a href="https://www.linkedin.com/in/dwdeanblack/" target="_blank" rel="noreferrer"> <img className="p-2"
                                            src="img/team/linkedin.svg" alt=""/></a>
                                </div>
                            </div>
        </Slider>
        <p className="mt-5 mb-6 team-name"><span className="font-bold text-2xl">Dean Black</span><br/>
                            Project & Research Lead</p>
        </article>

        <article>
        <Slider {...settings} className="w-full bg-blue-900">
                                <img src="/img/team/rosanna1.webp"
                                    className="img-responsive team" alt=""/>

                            <div>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                Rosanna is a graphic design graduate with a background in language and literature,
                                    who combines motion graphics, colour, typography and illustration to bring projects
                                    to life and communicate stories.
                                </p>
                              </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Dream
                                    Project?<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                "A Community Arts Festival filled with Interactive Exhibitions."
                                </p>
                            </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Get in touch<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                <a href="mailto:rosanna@urbanscaleinterventions.com">rosanna@urbanscaleinterventions.com</a>
                                </p>

                                <div className="flex justify-center pt-2">
                                    <a href="https://www.linkedin.com/in/rosannaokane" target="_blank" rel="noreferrer"> <img className="p-2"
                                            src="img/team/linkedin.svg" alt=""/></a>
                                </div>
                            </div>
        </Slider>
        <p className="mt-5 mb-6 team-name"><span className="font-bold text-2xl">Rosanna O'Kane</span><br/>
                            Graphic Designer </p>
        </article>

        <article>
        <Slider {...settings} className="w-full bg-blue-900">
                                <img src="/img/team/liam1.webp"
                                    className="img-responsive team" alt=""/>

                            <div>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                Liam is a Computer Science graduate from the University of York with a background in History. 
                                He focuses on making innovative user interfaces and creating engaging interactive elements.
                                </p>
                              </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Dream
                                    Project?<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                "Scouting players via automated data analysis for premier league football teams."
                                </p>
                            </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Get in touch<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                <a href="mailto:liam@urbanscaleinterventions.com">liam@urbanscaleinterventions.com</a>
                                </p>

                                <div className="flex justify-center pt-2">
                                    <a href="https://www.linkedin.com/in/liam-daniel-wallace/" target="_blank" rel="noreferrer"> <img className="p-2"
                                            src="img/team/linkedin.svg" alt=""/></a>
                                </div>
                            </div>
        </Slider>
        <p className="mt-5 mb-6 team-name"><span className="font-bold text-2xl">Liam Wallace</span><br/>
                           Front-End Developer </p>
        </article>

        <article>
        <Slider {...settings} className="w-full bg-blue-900">
                                <img src="/img/team/josh1.webp"
                                    className="img-responsive team" alt=""/>

                            <div>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                Josh's experience is in leading strategy and operations for place based public and private organisations. 
                                His interests sit with finding innovative ways people and places can grow and thrive together through balanced, 
                                sustainable strategies.
                                </p>
                              </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Dream
                                    Project?<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                "One where we connect all the green spaces in a city together to create one big, thriving park."
                                </p>
                            </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Get in touch<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                <a href="mailto:josh@urbanscaleinterventions.com">josh@urbanscaleinterventions.com</a>
                                </p>

                                <div className="flex justify-center pt-2">
                                    <a href="https://www.linkedin.com/in/joshuawatts1/" target="_blank" rel="noreferrer"> <img className="p-2"
                                            src="img/team/linkedin.svg" alt=""/></a>
                                </div>
                            </div>
        </Slider>
        <p className="mt-5 mb-6 team-name"><span className="font-bold text-2xl">Josh Watts</span><br/>
        Associate Director</p>
        </article>

        <article>
        <Slider {...settings} className="w-full bg-blue-900">
                                <img src="/img/team/kate1.webp"
                                    className="img-responsive team" alt=""/>

                            <div>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                Kate is an Architect with a Doctorate in heritage conservation. She specialises in bespoke design and heritage projects, with a particular focus on community engagement. 
                                She has worked for both public and private sector clients, including UNESCO, ICCROM, King’s College London and the British Council. 
                                </p>
                              </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Dream
                                    Project?<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                "One that has connection and meaning and is truly accessible to its communities (and amazing cycle facilities!)"
                                </p>
                            </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Get in touch<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                <a href="mailto:kate@urbanscaleinterventions.com">kate@urbanscaleinterventions.com</a>
                                </p>

                                <div className="flex justify-center pt-2">
                                
                                </div>
                            </div>
        </Slider>
        <p className="mt-5 mb-6 team-name"><span className="font-bold text-2xl">Kate Ashley</span><br/>
        Team Lead</p>
        </article>


        <article>
        <Slider {...settings} className="w-full bg-blue-900">
                                <img src="/img/team/lesley-ann1.webp"
                                    className="img-responsive team" alt=""/>

                            <div>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                Lesley-Ann has over 15 years’ experience of delivering strategic tourism led initiatives and major event delivery. 
                                Bringing together the disciplines of strategic planning, governance, marketing, branding, project management, event delivery and stakeholder engagement to unlock the potential of key strategic assets. 
                                </p>
                              </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Dream
                                    Project?<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                "One where I dont have to write the business case."
                                </p>
                            </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Get in touch<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                <a href="mailto:lesley-ann@urbanscaleinterventions.com">lesley-ann@urbanscaleinterventions.com</a>
                                </p>

                                <div className="flex justify-center pt-2">
                                
                                </div>
                            </div>
        </Slider>
        <p className="mt-5 mb-6 team-name"><span className="font-bold text-2xl">Lesley-Ann O'Donnell</span><br/>
        Associate Director</p>
        </article>

        <article className="">
        <Slider {...settings} className="w-full team-1 bg-blue-900 team-box">
                                <img src="/img/team/Fionnuala11.png"
                                    className="img-responsive team" alt=""/>

                            <div className="">
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                Fionnuala comes to USI with a core understanding in what makes visitors tick, applying knowledge from delivering effective interpretation and engaging programming, providing leadership in visitor experience, and commercial growth in events programmes, to ensure a world class experience.
                                </p>
                              </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Dream
                                    Project?<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                    "Something completely unexpected"
                                </p>
                            </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Get in touch<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                <a href="mailto:ralf@urbanscaleinterventions.com">fionnuala@urbanscaleinterventions.com</a>
                                </p>

                                <div className="flex justify-center pt-2">
                                    <a href="https://www.linkedin.com/in/ralf-alw/" target="_blank" rel="noreferrer"> <img className="p-2"
                                            src="/img/team/linkedin.svg" alt=""/></a>
                                </div>
                            </div>
        </Slider>
        <p className="mt-5 mb-6 team-name"><span className="font-bold text-2xl">Fionnuala Sweeney</span><br/>Studio Manager</p>
        </article>

        <article className="">
        <Slider {...settings} className="w-full team-1 bg-blue-900 team-box">
                                <img src="/img/team/Sarah11.png"
                                    className="img-responsive team" alt=""/>

                            <div className="">
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                Sarah supports a range of projects with research discovering innovation solutions and ecological improvements, ensuring  each project strives to reduce our impact on the planet, and contributes to sustainable development. 
                                </p>
                              </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Dream
                                    Project?<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                    "Something renewable and green"
                                </p>
                            </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Get in touch<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                <a href="mailto:ralf@urbanscaleinterventions.com">sarah@urbanscaleinterventions.com</a>
                                </p>

                                <div className="flex justify-center pt-2">
                                    <a href="https://www.linkedin.com/in/" target="_blank" rel="noreferrer"> <img className="p-2"
                                            src="/img/team/linkedin.svg" alt=""/></a>
                                </div>
                            </div>
        </Slider>
        <p className="mt-5 mb-6 team-name"><span className="font-bold text-2xl">Sarah Keating</span><br/>Project Coordinator- Research & Sustainability</p>
        </article>

        <article className="">
        <Slider {...settings} className="w-full team-1 bg-blue-900 team-box">
                                <img src="/img/team/Kate11.png"
                                    className="img-responsive team" alt=""/>

                            <div className="">
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                Kate is an advocate for regenerative and collaborative design that builds more resilient communities – places where people and nature can thrive. Her background is in urban planning, sustainability and property economics and she has over 10 years’ experience working across the public and private sector. 
                                </p>
                              </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Dream
                                    Project?<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                    "Banana Block"
                                </p>
                            </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Get in touch<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                <a href="mailto:ralf@urbanscaleinterventions.com">kate@urbanscaleinterventions.com</a>
                                </p>

                                <div className="flex justify-center pt-2">
                                    <a href="https://www.linkedin.com/in/" target="_blank" rel="noreferrer"> <img className="p-2"
                                            src="/img/team/linkedin.svg" alt=""/></a>
                                </div>
                            </div>
        </Slider>
        <p className="mt-5 mb-6 team-name"><span className="font-bold text-2xl">Kate Hogarth</span><br/>Senior Project Lead - Research & Strategy </p>
        </article>

        <article className="">
        <Slider {...settings} className="w-full team-1 bg-blue-900 team-box">
                                <img src="/img/team/GAVIN11.png"
                                    className="img-responsive team" alt=""/>

                            <div className="">
                                <p className="text-center  px-12 py text-white text-xs xl:text-base font-light">
                                  
                                </p>
                              </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Dream
                                    Project?<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                    "Portview"
                                </p>
                            </div>

                            <div>
                                <p className="text-center  px-12 py text-white text-sm md:text-xl font-black mb-2">
                                    Get in touch<br/>
                                </p>
                                <p className="text-center  px-12 py text-white text-xs md:text-base font-light">
                                <a href="mailto:ralf@urbanscaleinterventions.com">gavin@urbanscaleinterventions.com</a>
                                </p>

                                <div className="flex justify-center pt-2">
                                    <a href="https://www.linkedin.com/in/" target="_blank" rel="noreferrer"> <img className="p-2"
                                            src="/img/team/linkedin.svg" alt=""/></a>
                                </div>
                            </div>
        </Slider>
        <p className="mt-5 mb-6 team-name"><span className="font-bold text-2xl">Gavin Campbell</span><br/>Architect</p>
        </article>

      

       
        </div>
    );
  }
}